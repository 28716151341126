<template>
	<div class="block sm:flex sm:gap-10 mt-10 sm:mt-20">
		<div class="w-full sm:w-1/4 mb-7 sm:mb-0">
			<img
				src="../../assets/images/cnee1.png"
				class="rounded-xl w-96"
				alt="imagine-about"
				loading="lazy"
			/>
		</div>
		<div class="w-full sm:w-3/4 text-left">
			<p class="mb-4 text-ternary-dark dark:text-ternary-light text-lg">
				Centrul Naţional de Evaluare şi Examinare, organ de specialitate al administraţiei publice centrale, cu personalitate juridică, specializat în evaluarea educaţională, în subordinea Ministerului Educaţiei Naționale, este organizat și funcționează în baza H.G. nr. 1401/2009 privind înfiinţarea, organizarea şi funcţionarea Centrului Naţional de Evaluare şi Examinare cu modificările și completările ulterioare.
				</p>
			<p class="mb-4 text-ternary-dark dark:text-ternary-light text-lg">
			Centrul Naţional de Evaluare şi Examinare are ca are ca scop coordonarea ştiinţifică şi profesională a sistemului naţional de evaluare şi examinare în învăţământul preuniversitar şi coordonarea evaluării manualelor şcolare.
			</p>
			<p class="mb-4 text-ternary-dark dark:text-ternary-light text-lg">
			Centrul Naţional de Evaluare şi Examinare are următoarele atribuţii:
			</p>

			<p class="mb-4 text-ternary-dark dark:text-ternary-light text-lg">
			<ul>
			<li>a)asigură elaborarea probelor pentru evaluările, examenele şi concursurile naţionale;</li>
			<li>b)elaborează, împreună cu direcţiile specializate din Ministerul Educaţiei, Cercetării şi Inovării, metodologii, proceduri şi regulamente pentru organizarea şi desfăşurarea examenelor şi evaluărilor în învăţământul preuniversitar, pe care le supune aprobării ministrului educaţiei, cercetării şi inovării;</li>
			<li>c)efectuează la solicitarea Ministerului Educaţiei Naţionale expertize şi sondaje de evaluare a rezultatelor şcolare şi a eficienţei învăţământului, furnizând ministerului un feedback sistematic şi operativ, în scopul adoptării măsurilor de optimizare a activităţii în învăţământul preuniversitar;</li>
			<li>d)elaborează, la solicitarea Ministerului Educaţiei Naţionale în vederea informării publice, rapoarte privind rezultatele înregistrate la examenele şi evaluările naţionale, evidenţiind oportunitatea adoptării de către ministrul educaţiei naţionale a unor decizii cu privire la factorii care permit îmbunătăţirea performanţelor şcolare;</li>
			<li>e)coordonează, potrivit art. 69 din Legea educaţiei naţionale nr. 1/2011, cu modificările şi completările ulterioare, activităţile în vederea aprobării auxiliarelor didactice pentru învăţământul preuniversitar, conform metodologiei aprobate prin ordin al ministrului educaţiei naţionale, care se publică în Monitorul Oficial al României. Categoriile de auxiliare care intră în procedura derulată de către Centrul Naţional de Evaluare şi Examinare în vederea aprobării se stabilesc prin ordin al ministrului educaţiei naţionale;</li>
			<li>f)acordă sprijin tehnic şi de specialitate în realizarea unor acţiuni de evaluare întreprinse de minister, inspectorate şcolare, unităţi de învăţământ;</li>
			<li>g)elaborează şi realizează programe de proiectare a unor teste standardizate pentru principalele discipline/module şcolare, în funcţie de nivelurile educaţionale sau de ciclurile curriculare;</li>
			<li>h)organizează programe de pregătire a membrilor comisiilor de examen, a examinatorilor şi a altor persoane implicate în desfăşurarea examenelor şi sprijină acţiunile de perfecţionare a personalului didactic în realizarea proceselor de evaluare curentă a progresului şcolar;</li>
			<li>i)desfăşoară activităţi de cercetare în domeniul evaluării educaţionale;</li>
			<li>j)propune conducerii Ministerului Educaţiei, Cercetării şi Inovării iniţierea unor proiecte de acte normative care fac referire la evaluarea de proces şi performanţa în învăţământul preuniversitar;</li>
			<li>k)participă la proiecte şi la programe naţionale şi internaţionale în domeniul evaluării în învăţământul preuniversitar;</li>
			<li>l)constituie şi coordonează activitatea unor grupuri de lucru, remunerate conform legii, compuse din specialişti şi din cadre didactice cu experienţă, în scopul elaborării subiectelor pentru evaluările, examenele şi concursurile naţionale şi construirii şi actualizării bazelor de date cu instrumente de evaluare;</li>
			<li>l1)asigură, împreună cu direcţiile specializate din Ministerul Educaţiei Naţionale, elaborarea subiectelor pentru olimpiadele şcolare, faza judeţeană;</li>
			<li>m)coordonează derularea mecanismelor de evaluare a proiectelor de manuale şcolare pentru învăţământul preuniversitar, conform metodologiei aprobate prin ordin al ministrului educaţiei naţionale. În realizarea acestei atribuţii cooptează evaluatori - cadre didactice şi specialişti externi, remuneraţi conform legii;</li>
			<li>n)realizează, la solicitarea Ministerului Educaţiei Naţionale, expertize şi sondaje în unităţile de învăţământ cu privire la procesul de alegere şi utilizare a manualelor şcolare;</li>
			<li>o)coordonează activitatea de derulare a mecanismelor de reevaluare a manualelor şcolare, la cererea ministrului educaţiei, cercetării şi inovării;</li>
			<li>p)formează experţi evaluatori de manuale şcolare, prin derularea de programe în acest sens;</li>
			</ul>
			</p>
			<!-- <p class="mb-4 text-ternary-dark dark:text-ternary-light text-lg">
				Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nihil
				vel illum asperiores dignissimos cumque quibusdam et fugiat
				voluptatem nobis suscipit explicabo, eaque consequatur nesciunt,
				fugit eligendi corporis laudantium adipisci soluta?
			</p> -->

			<!-- <p class="text-ternary-dark dark:text-ternary-light text-lg">
				Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nihil
				vel illum asperiores dignissimos cumque quibusdam et fugiat
				voluptatem nobis suscipit explicabo, eaque consequatur nesciunt,
				fugit eligendi corporis laudantium adipisci soluta?
			</p> -->
			<br><br>
			<p class="mb-4 text-ternary-dark dark:text-ternary-light text-lg">
				# Date de contact:
				<br>
				Str. General Berthelot nr. 26, Sector 1, 010168, Bucureşti
				<br>
				Tel: +40.21.314.44.11, Fax: +40.21.310.32.07
				<br>
				Web site: http://www.rocnee.eu
				<br>
				Mail: office@rocnee.eu
				<br><br>
				# Program de lucru:
				<br>
				Luni-Joi: 08.00 -- 16.30
				<br>
				Vineri: 08.00 -- 14.00
				<br><br>
				# Pentru alte informații vă rugăm să ne lăsați mesajul dumneavoastră la <a href="/contact" target="_blank">pagina de contact</a>  !
			</p>
		</div>
	</div>
</template>

<script>
export default {
	setup() {},
};
</script>


<style scoped>
a:link {
  color: pink;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: green;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: red;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: yellow;
  background-color: transparent;
  text-decoration: underline;
}
</style>