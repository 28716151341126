<template>
	<div class="mt-10 sm:mt-20">
		<!-- <p
			class="text-2xl sm:text-4xl text-primary-dark dark:text-primary-light font-sans font-semibold"
		>
			Some of the best companies I worked with
		</p>
		<div class="grid grid-cols-2 sm:grid-cols-4 mt-10 sm:mt-20 gap-2">
			<div>
				<img
					src="../../assets/images/brands/amazon_gray.png"
					alt="Amazon"
					class="w-64 py-5 px-10 border border-ternary-light dark:border-ternary-dark shadow-sm rounded-xl mb-8 cursor-pointer"
				/>
			</div>
			<div>
				<img
					src="../../assets/images/brands/sony_gray.png"
					alt="SONY"
					class="w-64 py-5 px-10 border border-ternary-light dark:border-ternary-dark shadow-sm rounded-xl mb-8 cursor-pointer"
				/>
			</div>
			<div>
				<img
					src="../../assets/images/brands/adidas_gray.png"
					alt="Adidas"
					class="w-64 py-5 px-10 border border-ternary-light dark:border-ternary-dark shadow-sm rounded-xl mb-8 cursor-pointer"
				/>
			</div>
			<div>
				<img
					src="../../assets/images/brands/fila_gray.png"
					alt="FILA"
					class="w-64 py-5 px-10 border border-ternary-light dark:border-ternary-dark shadow-sm rounded-xl mb-8 cursor-pointer"
				/>
			</div>
			<div>
				<img
					src="../../assets/images/brands/nb_gray.png"
					alt="NB"
					class="w-64 py-5 px-10 border border-ternary-light dark:border-ternary-dark shadow-sm rounded-xl mb-8 cursor-pointer"
				/>
			</div>
			<div>
				<img
					src="../../assets/images/brands/samsung_gray.png"
					alt="Samsung"
					class="w-64 py-5 px-10 border border-ternary-light dark:border-ternary-dark shadow-sm rounded-xl mb-8 cursor-pointer"
				/>
			</div>
			<div>
				<img
					src="../../assets/images/brands/canon_gray.png"
					alt="Canon"
					class="w-64 py-5 px-10 border border-ternary-light dark:border-ternary-dark shadow-sm rounded-xl mb-8 cursor-pointer"
				/>
			</div>
			<div>
				<img
					src="../../assets/images/brands/puma_gray.png"
					alt="Puma"
					class="w-64 py-5 px-10 border border-ternary-light dark:border-ternary-dark shadow-sm rounded-xl mb-8 cursor-pointer"
				/>
			</div>
		</div> -->
	</div>
</template>

<script>
export default {
	setup() {},
};
</script>
