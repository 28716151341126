<template>
	<div class="mt-10 sm:mt-20 bg-primary-light dark:bg-ternary-dark shadow-sm">
		<!-- <div
			class="container mx-auto py-20 block sm:flex sm:justify-between sm:items-center"
		>
			<div class="mb-20 sm:mb-0">
				<p
					class="text-4xl font-bold text-secondary-dark dark:text-secondary-light mb-2"
				>
					12
				</p>
				<span class="text-md text-ternary-dark dark:text-ternary-light"
					>Years of experience</span
				>
			</div>
			<div class="mb-20 sm:mb-0">
				<p
					class="text-4xl font-bold text-secondary-dark dark:text-secondary-light mb-2"
				>
					20k+
				</p>
				<span class="text-md text-ternary-dark dark:text-ternary-light"
					>Stars on GitHub</span
				>
			</div>
			<div class="mb-20 sm:mb-0">
				<p
					class="text-4xl font-bold text-secondary-dark dark:text-secondary-light mb-2"
				>
					92%
				</p>
				<span class="text-md text-ternary-dark dark:text-ternary-light"
					>Positive feedback</span
				>
			</div>
			<div>
				<p
					class="text-4xl font-bold text-secondary-dark dark:text-secondary-light mb-2"
				>
					77
				</p>
				<span class="text-md text-ternary-dark dark:text-ternary-light"
					>Projects completed</span
				>
			</div>
		</div> -->
	</div>
</template>

<script>
export default {
	setup() {},
};
</script>
